import PhotoSwipeLightbox from '/js/vendor-scripts/photoswipe/photoswipe-lightbox.esm.min.js';
import initNativeFullscreen from '/js/sb-scripts/esm/photo-gallery.native-fullscreen.esm.js';
import initPhotoGalleryEllipsis from '/js/sb-scripts/esm/photo-gallery.ellipsis.esm.js';

const getImageSrc = (image, size) => `/imagedata/${image.imageId}/${image.imageId}_${image.imageVersion}_${size}.jpg`;

const getImageSrcset = image => config.imageSizes.map(size => `${getImageSrc(image, size)} ${size}w`).join(', ');

const getMaxImageSize = () => config.imageSizes[config.imageSizes.length - 1];

const generateImageDataSource = image => ({
  srcset: getImageSrcset(image),
  src: getImageSrc(image, getMaxImageSize()),
  w: getMaxImageSize(),
  h: getMaxImageSize() * config.imageRatio,
  alt: image.description,
});

const openPhotoGallery = (images, customGallerySelector, customChildSelector, openHandler, closeHandler) => {
  const options = {
    gallerySelector: customGallerySelector,
    childSelector: customChildSelector,
    getViewportSizeFn: () => ({
      x: window.innerWidth,
      y: window.innerHeight,
    }),
    showHideAnimationType: 'zoom',
    pswpModule: '/js/vendor-scripts/photoswipe/photoswipe.esm.min.js',
    pswpCSS: '/css/vendor-styles/photoswipe/photoswipe.css',
    preloader: false,
    showAnimationDuration: 500,
    hideAnimationDuration: 500,
  };
  const lightbox = new PhotoSwipeLightbox(options);
  lightbox.on('itemData', e => {
    const newItemData = generateImageDataSource(images[e.index]);
    e.itemData = {
      ...e.itemData,
      ...newItemData,
    };
  });
  lightbox.on('numItems', e => {
    e.numItems = images.length;
  });
  lightbox.on('destroy', () => {
    if (closeHandler) {
      closeHandler();
    }
  });
  lightbox.on('firstUpdate', () => {
    if (openHandler) {
      openHandler();
    }
  });
  lightbox.on('uiRegister', () => {
    const { ui } = lightbox.pswp;
    ui.registerElement({
      name: 'custom-caption',
      order: 9,
      isButton: false,
      appendTo: 'root',
      onInit: (el, pswp) => {
        pswp.on('change', () => {
          const currSlideData = pswp.currSlide.data;
          // eslint-disable-next-line no-param-reassign
          el.innerHTML = currSlideData.alt
            ? `<div class="caption-container"><div class="caption-content">${currSlideData.alt}</div></div>`
            : '';
        });
      },
    });
  });
  initNativeFullscreen(lightbox);
  initPhotoGalleryEllipsis(lightbox);
  lightbox.init();
};
window.openPhotoGallery = openPhotoGallery;
